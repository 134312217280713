import Basis from './images/team-basistjenester.png';
import DX from './images/team-dx.png';
import Kanaler from './images/team-kanaler.png';
import Kjop from './images/team-kjop.png';
import Kunde from './images/team-kunde.png';
import Kundeinnsikt from './images/team-kundeinnsikt.png';
import NyeTjenester from './images/team-nye-tjenester.png';
import Reise from './images/team-reise.png';

export const getTeamSeating = (team: string) => {
    switch (team) {
        case 'team-basistjenester':
            return Basis;
        case 'team-dx':
            return DX;
        case 'team-kanaler':
            return Kanaler;
        case 'team-kjop':
            return Kjop;
        case 'team-kunde':
            return Kunde;
        case 'team-kundeinnsikt':
            return Kundeinnsikt;
        case 'team-nye-tjenester':
            return NyeTjenester;
        case 'team-reise':
            return Reise;
        default:
            return undefined;
    }
}
