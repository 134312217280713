import React from "react";
import {Card, CardContent, CardHeader, Divider} from "@material-ui/core";
import {useEntity} from "@backstage/plugin-catalog-react";
import {getTeamSeating} from "./seatingUtil";

export const GroupSeatingCard = () => {
    const { entity } = useEntity();
    const teamSeating = getTeamSeating(entity.metadata.name);
    if (!teamSeating)
        return <></>

    return (
        <Card className="gridItem">
            <CardHeader
                title="Where do we sit?"
                style={{marginBottom: "12px"}}
            />
            <Divider />
            <CardContent>
                <img src={teamSeating} alt={entity.metadata.name} style={{display: "block", maxWidth: "100%"}}/>
            </CardContent>

        </Card>
    )
}