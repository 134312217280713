import React from 'react';
import VyLogo from './logo/vy.png';

const LogoFull = () => {

  return (
      <img src={VyLogo} alt="Vy logo" style={{width: 'auto', height: 100}}/>
  );
};

export default LogoFull;
