import {ScmAuth, ScmIntegrationsApi, scmIntegrationsApiRef,} from '@backstage/integration-react';

import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    discoveryApiRef,
    githubAuthApiRef,
    oauthRequestApiRef,
} from '@backstage/core-plugin-api';

import {GithubAuth} from '@backstage/core-app-api';

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory({
        api: githubAuthApiRef,
        deps: {
            configApi: configApiRef,
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
        },
        factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
            GithubAuth.create({
                discoveryApi,
                oauthRequestApi,
                defaultScopes: ['read:user'],
                environment: configApi.getString('auth.environment'),
            }),
    }),
];