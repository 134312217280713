import {createBaseThemeOptions, createUnifiedTheme, defaultTypography, palettes} from "@backstage/theme";
import sporDesignTokens from '@vygruppen/spor-design-tokens';

const {
    color: {palette: sporColor},
} = sporDesignTokens;

const colors = {
    primary: sporColor.green['600'],
    secondary: sporColor.green['200'],
    error: sporColor.red['400'],
    warning: sporColor.orange['400'],
    info: sporColor.blue['400'],
    success: sporColor.green['400'],
    background: sporColor.white,
    navigation: {
        background: '#00453e',
        indicator: sporColor.teal['200'],
        selectedColor: sporColor.teal['100'],
        color: sporColor.white,
        hover: sporColor.teal['300'],
    },
    text: sporColor.grey['600'],
    link: sporColor.teal['500'],
};

export const vyTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.light,
            primary: {
                main: colors.primary
            },
            secondary: {
                main: colors.secondary,
            },
            error: {
                main: colors.error,
            },
            warning: {
                main: colors.warning,
            },
            info: {
                main: colors.info,
            },
            success: {
                main: colors.success,
            },
            background: {
                default: colors.background,
                paper: colors.background,
            },
            banner: {
                info: colors.info,
                error: colors.error,
                text: colors.text,
                link: colors.link,
            },
            navigation: {
                submenu: {
                    background: colors.navigation.background,
                },
                background: colors.navigation.background,
                indicator: colors.navigation.indicator,
                color: colors.navigation.color,
                selectedColor: colors.navigation.selectedColor,
                navItem: {
                    hoverBackground: colors.navigation.hover,
                },
            },
            code: {
                background: colors.secondary
            }
        },
        typography: {
            ...defaultTypography,
            fontFamily: 'Vy Sans',
        },
    }),
    components: {
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: "#F8F8F8",
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: colors.background,
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    display: 'inline-flex',
                    appearance: 'none',
                    '-webkit-box-align': 'center',
                    'align-items': 'center',
                    '-webkit-box-pack': 'center',
                    'justify-content': 'center',
                    'user-select': 'none',
                    'white-space': 'nowrap',
                    'vertical-align': 'middle',
                    outline: 'transparent solid 2px',
                    'outline-offset': '2px',
                    border: '0px',
                    'border-radius': '30px',
                    'font-weight': '700',
                    'transition-property':
                        'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
                    'transition-duration': '200ms',
                    'padding-inline-start': '18px',
                    'padding-inline-end': '18px',
                    'min-height': '42px',
                    'min-width': '42px',
                    position: 'relative',
                },
            }
        },
        BackstageHeader: {
            styleOverrides: {
                header: {
                    backgroundImage: 'none',
                    boxShadow: 'none',
                    background: colors.navigation.background,
                    borderBottom: `4px solid ${sporColor.teal['500']}`,
                },
            }
        },
        BackstageSidebar: {
            styleOverrides: {
                drawer: {
                    background: colors.navigation.background
                }
            }
        }
    }
})